import {
  ChangeWoodoSectionStatus,
  ConditionLevel,
  ExternalOrderChannel,
  StockMemoType,
  StockSearchSort,
  StockStatus,
} from "constants/Server";
import { InfiniteList } from "types/Common";
import {
  BulkConvertConsignmentFakeStockErrorDto,
  ExternalStock,
  RemoteStock0,
  RemoteStockMemo,
  StockLog,
} from "types/Content";
import fetchn from "utils/Fetchn";

const StockApi = (signal?) => {
  return {
    getStocks: async ({
      title,
      bookId,
      consigned,
      libraryId,
      page,
      size,
      includedStatus,
      sellableInShop,
      payback,
      shopPurchased,
      excludeFake,
      isSpecial,
      sellable,
      stockIds,
      woodoCodeList,
      woodoCode,
      rentalFake,
      sort,
    }: {
      title?: string;
      bookId?: number;
      consigned?: boolean;
      libraryId?: number;
      page: number;
      size: number;
      includedStatus?: StockStatus[];
      sellableInShop?: boolean;
      payback?: boolean;
      shopPurchased?: boolean;
      excludeFake?: boolean;
      isSpecial?: boolean;
      sellable?: boolean;
      stockIds?: number[];
      woodoCodeList?: string[];
      woodoCode?: string;
      rentalFake?: string;
      sort?: StockSearchSort;
    }) => {
      // POST /manager/v0/stocks/search
      const { data } = await fetchn.request<InfiniteList<RemoteStock0>>({
        method: "POST",
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/stocks/search`,
        data: {
          title,
          bookId,
          consigned,
          libraryId,
          page: {
            page,
            size,
          },
          includedStatus,
          sellableInShop,
          payback,
          shopPurchased,
          excludeFake,
          isSpecial,
          sellable,
          stockIds,
          woodoCodeList,
          woodoCode,
          rentalFake,
          sort,
        },
        signal,
      });
      return data;
    },
    getStock: async (stockId: number) => {
      // GET /manager/v0/stocks/{id}
      const { data } = await fetchn.request<RemoteStock0>({
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/stocks/${stockId}`,
        signal,
      });
      return data;
    },
    editStock: async ({
      stockId,
      quantity,
      woodoCode,
      woodoSectionCode,
      rentalPrice,
      conditionLevel,
      containingSupplement,
      damageDescription,
      shopPurchased,
      lost,
    }: {
      stockId: number;
      quantity: number;
      woodoCode: string;
      woodoSectionCode: string;
      rentalPrice: number;
      conditionLevel: ConditionLevel;
      containingSupplement: boolean;
      damageDescription: string;
      shopPurchased: boolean;
      lost: boolean;
    }) => {
      // PATCH /manager/v0/stocks/{id}
      await fetchn.request({
        method: "PATCH",
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/stocks/${stockId}`,
        data: {
          conditionLevel,
          containingSupplement,
          quantity,
          rentalPrice,
          woodoCode,
          woodoSectionCode,
          damageDescription,
          shopPurchased,
          lost,
        },
        signal,
      });
    },
    addStockSellable: async ({
      bookId,
      quantity,
      woodoSectionCode,
      payback,
    }: {
      bookId: number;
      quantity: number;
      woodoSectionCode: string;
      payback: boolean;
    }) => {
      // POST /manager/v0/stocks/sellable-in-shop
      await fetchn.request({
        method: "POST",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/stocks/sellable-in-shop`,
        data: {
          bookId,
          quantity: +quantity,
          woodoSectionCode,
          payback,
        },
        signal,
      });
    },
    bulkAddWoodoShop: async (
      data: { isbn: string; woodoSectionCode: string }[]
    ) => {
      // POST /manager/v0/stocks/woodo-shop/reset
      const { data: response } = await fetchn.request<{
        existsOmission: boolean;
      }>({
        method: "POST",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/stocks/sellable-in-shop/bulk-add`,
        data: { items: data },
        signal,
      });

      return response;
    },
    bulkAddParcelRent: async (
      data: {
        libraryId: number;
        isbn: string;
        woodoSectionCode: string;
        woodoCode: string;
        conditionLevel: ConditionLevel;
        shopPurchased: boolean;
        containingSupplement: boolean;
      }[]
    ) => {
      // POST /manager/v0/stocks/woodo-shop/reset
      await fetchn.request({
        method: "POST",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/stocks/parcel-rent/bulk-add`,
        data: data,
        signal,
      });
    },
    discard: async (stockId: number) => {
      // DELETE /manager/v0/stocks/{id}
      await fetchn.request({
        method: "DELETE",
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/stocks/${stockId}`,
        signal,
      });
    },
    bulkDiscard: async (stockIds: number[]) => {
      // PUT /manager/v0/stocks/bulk-discard
      await fetchn.request({
        method: "PUT",
        url:
          process.env.REACT_APP_SERVER_URI + `/manager/v0/stocks/bulk-discard`,
        data: { stockIds: stockIds },
        signal,
      });
    },
    restore: async (stockId: number) => {
      // PATCH /manager/v0/stocks/${stockId}/restore
      await fetchn.request({
        method: "PATCH",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/stocks/${stockId}/restore`,
        signal,
      });
    },
    restoreChangedOwner: async (stockId: number) => {
      // PATCH /manager/v0/stocks/${stockId}/restore-changed-owner
      await fetchn.request({
        method: "PATCH",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/stocks/${stockId}/restore-changed-owner`,
        signal,
      });
    },
    getMemos: async (stockId: number) => {
      // GET /manager/v0/stocks/{stockId}/memos
      const { data } = await fetchn.request<RemoteStockMemo[]>({
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/stocks/${stockId}/memos`,
        signal,
      });
      return data;
    },
    getMemo: async (stockId: number, memoId: number) => {
      // GET /manager/v0/stocks/{stockId}/memos/{memoId}
      const { data } = await fetchn.request<RemoteStockMemo>({
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/stocks/${stockId}/memos/${memoId}`,
        signal,
      });
      return data;
    },
    addMemos: async ({
      stockId,
      message,
      type,
      imageUrls,
    }: {
      stockId: number;
      message: string;
      type: StockMemoType;
      imageUrls?: string[];
    }) => {
      // POST /manager/v0/stocks/{stockId}/memos
      await fetchn.request({
        method: "POST",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/stocks/${stockId}/memos`,
        data: {
          message,
          type,
          imageUrls,
        },
        signal,
      });
    },
    modifyMemos: async ({
      stockId,
      memoId,
      message,
      type,
      imageUrls,
    }: {
      stockId: number;
      memoId: number;
      message: string;
      type: StockMemoType;
      imageUrls?: string[];
    }) => {
      // PATCH /manager/v0/stocks/{stockId}/memos/{memoId}
      await fetchn.request({
        method: "PATCH",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/stocks/${stockId}/memos/${memoId}`,
        data: {
          message,
          type,
          imageUrls,
        },
        signal,
      });
    },
    deleteMemo: async (stockId: number, memoId: number) => {
      // DELETE /manager/v0/stocks/{stockId}/memos/{memoId}
      await fetchn.request({
        method: "DELETE",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/stocks/${stockId}/memos/${memoId}`,
        signal,
      });
    },
    updateWoodoSectionCode: async (
      values: Array<{
        woodoCode: string;
        woodoSectionCode: string;
      }>
    ) => {
      // POST /manager/v0/stocks/woodo-section-code
      const { data } = await fetchn.request<
        {
          stockId: number;
          woodoCode: string;
          woodoSectionCode: string;
          status: StockStatus;
          processStatus: ChangeWoodoSectionStatus;
        }[]
      >({
        method: "POST",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/stocks/woodo-section-code`,
        data: values,
        signal,
      });
      return data;
    },
    updateRentalFakeStocks: async (
      values: Array<{
        isbn: string;
        woodoCode: string;
        woodoSectionCode: string;
      }>
    ) => {
      // POST /manager/v0/stocks/bulk-convert-rental-fake-stocks
      const { data } = await fetchn.request<string[]>({
        method: "PUT",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/stocks/bulk-convert-rental-fake-stocks`,
        data: values,
        signal,
      });
      return data;
    },
    updateConsignmentFakeStocks: async (
      values: Array<{
        isbn: string;
        woodoCode: string;
        woodoSectionCode: string;
      }>
    ) => {
      // PUT /manager/v0/stocks/bulk-convert-consignment-fake-stocks
      const { data } = await fetchn.request<
        BulkConvertConsignmentFakeStockErrorDto[]
      >({
        method: "PUT",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/stocks/bulk-convert-consignment-fake-stocks`,
        data: values,
        signal,
      });

      return data;
    },
    changeOwnerToWoodo: async (stockIds: number[]) => {
      // POST /manager/v0/stocks/change-owner-to-woodo
      await fetchn.request({
        method: "POST",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/stocks/change-owner-to-woodo`,
        data: { stockIds },
        signal,
      });
    },
    bulkAddRentFakeStocks: async (
      data: {
        isbn: string;
        quantity: number;
      }[]
    ) => {
      // POST /manager/v0/stocks/rental-fake-stocks/bulk-add
      await fetchn.request({
        method: "POST",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/stocks/rental-fake-stocks/bulk-add`,
        data,
        signal,
      });
    },
    generateWoodoCode: async (generateCount: number) => {
      // POST /manager/v0/stocks/generate-woodo-code
      const { data } = await fetchn.request<{
        startWoodoCode: number;
        endWoodoCode: number;
      }>({
        method: "POST",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/stocks/generate-woodo-code`,
        data: { generateCount },
        signal,
      });

      return data;
    },
    bulkEditSellable: async (
      data: { stockId: number; sellable: boolean; sellingPrice: number }[]
    ) => {
      // POST /manager/v0/stocks/bulk-modify-sellable
      const { data: response } = await fetchn.request<number[]>({
        method: "POST",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/stocks/bulk-modify-sellable`,
        data: data,
        signal,
      });

      return response;
    },
    getLogList: async (stockId: number, page: number, size: number) => {
      // GET /manager/v0/stocks/{stockId}//stock-log
      const { data } = await fetchn.request<InfiniteList<StockLog>>({
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/stocks/${stockId}/stock-log`,
        signal,
      });
      return data;
    },
    getExternalStocks: async (
      channel: ExternalOrderChannel,
      page: number,
      size: number
    ) => {
      // GET /manager/v0/external-stocks
      const { data } = await fetchn.request<InfiniteList<ExternalStock>>({
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/external-stocks`,
        params: {
          channel,
          "page.page": page,
          "page.size": size,
        },
        signal,
      });
      return data;
    },
    syncExternalStockCount: async (data: {
      channel: ExternalOrderChannel;
      stockInfoList: {
        isbn: string;
        stockCount: number;
      }[];
    }) => {
      // POST /manager/v0/external-stocks
      await fetchn.request({
        method: "POST",
        url: process.env.REACT_APP_SERVER_URI + `/manager/v0/external-stocks`,
        data,
        signal,
      });
    },
    bulkDeleteExternalStock: async (data: {
      channel: ExternalOrderChannel;
      bookIds: number[];
    }) => {
      // POST /manager/v0/external-stocks/bulk-delete
      await fetchn.request({
        method: "POST",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/external-stocks/bulk-delete`,
        data,
        signal,
      });
    },
    convertToStorageFeeCharge: async (stockIds: number[]) => {
      // POST /manager/v0/stocks/convert-to-storage-fee-charge
      await fetchn.request({
        method: "POST",
        url:
          process.env.REACT_APP_SERVER_URI +
          `/manager/v0/stocks/convert-to-storage-fee-charge`,
        data: { stockIds },
        signal,
      });
    },
  };
};

export default StockApi;
